/* eslint-disable camelcase */
import { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { Icon } from 'react-icons-kit';
// import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward';
// import { ic_add } from 'react-icons-kit/md/ic_add';
import { FinishedMessageWrapper } from '@/styles/questions.styles';
import {
  CardWrapper,
  // StageNavigator,
  FooterCTANavigation,
  FooterNavigator
} from '@/styles/index';
import { PollProvider, usePollContext } from '@/contexts/poll-context';
import { getLocalPollResponse } from '@/utils/poll';
import { createNewResponse, updateResponse } from '@/utils/data';
import { responseDataParser, hiddenResponsesParser, visiblePageCondition } from '@/utils/parser';
import { cleanObject } from '@/utils/index';
import dynamic from 'next/dynamic';
// import PoweredBy from '@/components/Layout/PoweredBy';
import QuestionRenderer from '@/components/Questions';
// const QuestionRenderer = dynamic(() => import('@/components/Questions'), {
//   ssr: false,
//   loading: () => <div>loading...</div>
// });
import PollNProgress from '@/components/NXProgress/nxprogress';

const PoweredBy = dynamic(() => import('@/components/Layout/PoweredBy'), {
  ssr: false
});

const textLibrary = {
  en: {
    go_back: 'Go back',
    finish: 'Finish',
    next: 'Next',
    thanks: 'Thanks',
    success_message: 'We have successfully registered your responses'
  },
  es: {
    go_back: 'Atrás',
    finish: 'Finalizar',
    next: 'Siguiente',
    thanks: 'Gracias',
    success_message: 'Hemos registrado con éxito sus respuestas'
  }
};

const Poll = ({
  poll,
  model,
  defaultValues = {},
  onEditor,
  withBackground,
  examples,
  template,
  cta,
  embedded,
  onSubmittedResponse
}) => {
  // onEditor indicates if on editor view
  const { updateContext, responses, stage } = usePollContext();
  // const [stage, setStage] = useState(0); // required, for page navigation
  // const [responses, setResponses] = useState({}); // required, for data manipulation
  const [loading, setLoading] = useState(false); // change for state machine flow (idle, etc)
  // console.log({ defaultValues });
  const {
    handleSubmit,
    watch,
    control,
    getValues,
    setFocus,
    formState: { errors },
    reset
  } = useForm({
    defaultValues
    // defaultValues: responses,
    // reValidateMode: 'onBlur' // default onChange
    // context: responses
  });
  // console.log('with usePollContext');
  // console.log({ modelID });

  const submitBtnRef = useRef();
  const currentFocusedElementRef = useRef();
  // to refresh on model change
  useEffect(() => {
    // setStage(0);
    if (onEditor) {
      // if editor, always refresh with stage 0
      updateContext({ stage: 0 }, onEditor); // notify if on editor
    }
    // if (!onEditor) {
    //   // get local response state?
    //   const localResponse = { stage: 0 };
    //   updateContext(localResponse, onEditor); // notify if on editor
    // }
  }, [poll, onEditor]);

  useEffect(() => {
    // set progress on card
    PollNProgress.configure({ parent: '#poll-card' });

    const handleKeyDown = e => {
      const { keyCode, key } = e;
      const isEnter = key === 'Enter' || keyCode === 13;
      // handle enter key as submit
      if (isEnter) {
        if (currentFocusedElementRef && currentFocusedElementRef.current) {
          // check if current focused on textarea, to prevent submit action, and use default behavior.
          const isTextAreaFocused = currentFocusedElementRef.current.tagName === 'TEXTAREA';
          if (isTextAreaFocused) {
            return;
          }
        }
        e.preventDefault();
        e.stopPropagation();
        if (submitBtnRef && submitBtnRef.current) {
          // submit button click
          submitBtnRef.current.click();
        }
      }
    };
    const handleElementClick = e => {
      // set current selected/focused html node
      currentFocusedElementRef.current = e.target;
    };

    // add click selected html node and enter key as submit
    document.addEventListener('mousedown', handleElementClick);
    document.addEventListener('keydown', handleKeyDown);
    // clean listeners
    return () => {
      document.removeEventListener('mousedown', handleElementClick);
      document.removeEventListener('keydown', handleKeyDown);
      // finish pending progress and remove
      PollNProgress.done();
      PollNProgress.remove();
    };
  }, []);

  const {
    lang = 'en',
    title,
    description,
    // pages,
    enableGoBack,
    stageNavigation,
    showStageNavigation = true,
    showPollTitle = true,
    multiResponse = false,
    partialResponse = false
  } = model;

  const disabledBack = !enableGoBack || stage === 0;

  const getPages = args => {
    const allQuestions = args.pages.map(p => p.elements).flat();
    const hiddenResponses = hiddenResponsesParser({
      questions: allQuestions,
      data: { ...args.data }
    });
    const currentParsedData = { ...args.data, ...hiddenResponses };
    const pagesToShow = args.pages
      .map(p => {
        if (p.isVisible) {
          // check if visible condition
          const isPageVisible = visiblePageCondition({
            page: p,
            data: { ...currentParsedData }
          });
          if (isPageVisible) {
            // if visible condition, return page
            return p;
          }
          return null; // if not, return null
        }
        // return page
        return p;
      })
      .filter(Boolean);
    return pagesToShow;
  };

  const pages = getPages({
    pages: model.pages,
    data: { ...cleanObject(responses), ...cleanObject(getValues()) }
  });
  // console.log({ errors });
  // console.log({ pages });
  // set current page
  const page = pages[stage];
  // const disabledBack = !enableGoBack || !page || stage === 0;

  // useEffect(() => {
  //   // if not first page
  //   if (stage > 0) {
  //     // console.log('setting focus');
  //     // console.log({ page });
  //     if (page) {
  //       const questions = page?.elements;
  //       if (questions.length > 1) {
  //         // only if more than 1 questions
  //         const firstQuestionOnPage = questions[0];
  //         // console.log({ firstQuestionOnPage });
  //         // console.log({ data: watch() });
  //         const currentFormState = watch();
  //         const questionName = firstQuestionOnPage.name;
  //         if (questionName in currentFormState) {
  //           // console.log('question name on current state');
  //           setFocus(questionName);
  //         }
  //       }
  //     }
  //   }
  // }, [watch, setFocus, page, stage]);

  // SCROLL on page change
  useEffect(() => {
    // if not first page
    if (stage > 0) {
      // console.log('setting focus');
      // console.log({ page });
      if (window && window.self.scrollTo) {
        window.self.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }, [stage]);

  const goToStage = async (stageIndex, respData = {}) => {
    const currResp = getValues();
    const isGoingBack = stageIndex < stage;
    // console.log({ isGoingBack });
    // console.log({ currResp });
    const allQuestions = pages.map(p => p.elements).flat();
    const currResponses = {
      ...cleanObject(responses),
      ...cleanObject(currResp),
      ...cleanObject(respData)
    };
    // console.log({ currResponses });
    const hiddenResponses = hiddenResponsesParser({
      questions: allQuestions,
      data: { ...cleanObject(currResponses) }
    });
    // console.log({ hiddenResponses });
    let newResponses = {
      ...cleanObject(responses),
      ...cleanObject(currResp),
      ...cleanObject(respData),
      ...cleanObject(hiddenResponses)
    };
    // if going back stage, clean poll blank responses
    if (isGoingBack) {
      const nonNullResponses = Object.keys(newResponses).reduce((acc, curr) => {
        if (newResponses[curr]) {
          return { ...acc, [curr]: newResponses[curr] };
        }
        return { ...acc };
      }, {});
      // clean blank responses when going back
      newResponses = nonNullResponses;
      // reset poll validation state and keep curr responses
      reset(
        { ...defaultValues },
        {
          keepErrors: false,
          keepDirty: true,
          keepIsSubmitted: true,
          keepTouched: true,
          keepIsValid: true,
          keepSubmitCount: false
        }
      );
    }
    // console.log('gotoStage');
    // console.log({ newResponses });
    // await setResponses(newResponses);
    // await setStage(stageIndex);
    await updateContext({ responses: newResponses, stage: stageIndex }, onEditor); // notify if on editor
  };

  const handleProgress = currentState => {
    if (currentState === 'finish') {
      PollNProgress.done();
    }
    if (currentState === 'start') {
      PollNProgress.start();
    }
  };

  const handlePostData = async responseBody => {
    if (onEditor) {
      // console.log('on editor');
      return responseBody;
    }
    const {
      data: { responseId }
    } = responseBody;
    // start progress
    handleProgress('start');
    // const prevResponse = await getLocalPollResponse(model.id);
    // // const prevDataToUse = multiResponse ? {} : prevResponse;
    // console.log({ responseId });
    // if no prev responseId, create new response
    if (!responseId) {
      const { data: dataResponse } = await createNewResponse(responseBody);
      // finish progress
      handleProgress('finish');
      if (dataResponse) {
        return { response: dataResponse.response };
      }
    }
    // if prev response, then update response object
    // has prev responseId, and not multiResponse option, update prev response record
    // can update prev record if not multiResponse (updating prev record, or new pages; OR if partial response option)
    // const canUpdate = [!multiResponse, partialResponse].some(Boolean);
    if (responseId) {
      // console.log('UPDATING!');
      const { data: dataResponse } = await updateResponse(responseBody);
      // console.log({ dataResponse });
      // finish progress
      handleProgress('finish');
      if (dataResponse) {
        return { response: dataResponse.response };
      }
    }
    // // check prev response BUT no multiResponse option, create new response record
    const cleanedData = { ...responseBody.data };
    delete cleanedData.responseId;
    const newResponseBody = { ...responseBody, data: cleanedData };
    const { data: newDataResponse } = await createNewResponse(newResponseBody);
    // finish progress
    handleProgress('finish');
    if (newDataResponse) {
      return { response: newDataResponse.response };
    }
  };

  const onSubmit = async formValues => {
    await setLoading(true);
    const prevResponse = await getLocalPollResponse(model.id);
    // console.log({ prevResponse });
    let prevRecordId = {};
    // if prev response, and NOT multiResponse, pass local responseID
    if (prevResponse && !multiResponse) {
      const responseId = prevResponse?.responses?.responseId;
      if (responseId) {
        prevRecordId = { responseId };
      }
    }
    const allQuestions = pages.map(p => p.elements).flat();
    // console.log({ responses, formValues, prevRecordId });
    const consolidated = {
      ...responses,
      ...formValues,
      ...prevRecordId
    };
    const hiddenResponses = hiddenResponsesParser({
      questions: allQuestions,
      data: { ...cleanObject(consolidated) }
    });
    const data = { ...consolidated, ...hiddenResponses };
    // console.log({ data });
    const parsed = responseDataParser(data);
    const pollId = poll ? { pollID: poll.id } : {};
    const responseBody = { ...pollId, data: parsed };
    // console.log({ parsed });
    // console.log({ data });
    // 1.- check if more pages and collect raw responses
    // const totalPagesLength = pages.length;
    const nextPage = pages[stage + 1];
    // console.log({ ...responses, ...formValues });
    if (nextPage) {
      // if partial response and NOT on editor
      if (partialResponse && !onEditor) {
        const { response } = await handlePostData(responseBody);
        if (response) {
          await setLoading(false);
          return goToStage(stage + 1, { ...data, responseId: response.id }); // saving progress to localStorage
        }
      }
      // console.log('HAS NEXT PAGE');
      // if next page exists, collect responses and go to next page
      await setLoading(false);
      return goToStage(stage + 1, data);
    }
    // 2.- if no more pages, parse responses and post data to db
    // console.log('NO NEXT PAGE, SUBMIT!!!');
    // init progress status bar
    if (onEditor) {
      handleProgress('start');
      // console.log('onEditor: do not submit');
      await goToStage(stage + 1, data);
      await setLoading(false);
      // finish progress status bar
      handleProgress('finish');
      console.log({ parsed });
      return parsed;
    }
    // if (examples) {
    //   console.log('on Example: do not submit');
    //   // await goToStage(stage + 1);
    //   await setLoading(false);
    //   return parsed;
    // }

    // console.log(responseBody);
    // console.log('Submit response:');

    try {
      // const { data: respData } = await handlePostData(responseBody);
      const { response } = await handlePostData(responseBody);
      // console.log({ response });
      // if created successfully
      if (response) {
        // console.log('do something with response when created?');
        if (onSubmittedResponse && typeof onSubmittedResponse === 'function') {
          await setLoading(false);
          return onSubmittedResponse(response);
        }
        await goToStage(stage + 1, { ...data, responseId: response.id }); // saving progress to localStorage
      }
    } catch (err) {
      console.log(err);
    } finally {
      await setLoading(false);
    }
  };

  // console.log({ errors });
  // console.log({ responses });
  const stageValues = watch();
  // console.log({ stageValues });
  const showTitleHeader = showPollTitle && Boolean(title || description);
  return (
    <div
      className={`poll-container${withBackground ? ' with-background' : ''}${
        examples ? ' examples' : ''
      }${template ? ' template' : ''}`}
    >
      {showTitleHeader && (
        <div className="card-header">
          <h1 className="title">{title}</h1>
          <div className="description">{description}</div>
        </div>
      )}

      <CardWrapper id="poll-card" className={`${template ? 'card-template' : ''}`}>
        {/**
            {pages.length > 1 && showStageNavigation && (
        <StageNavigator>
          {pages.map((pag, i) => (
            <button
              type="button"
              data-active={stage === i}
              key={pag.id}
              aria-label={`Stage ${i + 1}`}
              disabled={loading || stageNavigation}
              aria-disabled={loading || stageNavigation}
              onClick={() => goToStage(i)}
            />
          ))}
        </StageNavigator>
      )}
       */}

        {/* {pages.map(page => (
        <div key={page.id} className='card-body'>
          <div className='body-header'>
            <h2 className='title'>{page.title}</h2>
            <div className='description'>{page.description}</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <QuestionRenderer
              questions={page.elements}
              control={control}
              errors={errors}
            />
            <button type='submit'>Enviar</button>
          </form>
        </div>
      ))} */}
        {page && (
          <div key={page.id} className="card-body">
            {!page.title && !page.description ? (
              <div />
            ) : (
              <div className="form-header">
                <h2 className="title">{page.title}</h2>
                <div className="description">{page.description}</div>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={loading}>
                <div className="form-body">
                  <QuestionRenderer
                    questions={page.elements}
                    control={control}
                    errors={errors}
                    responses={responses}
                    stageValues={stageValues}
                    disabled={loading}
                    defaultValues={defaultValues}
                    lang={lang}
                  />
                </div>
              </fieldset>

              {cta ? (
                <FooterCTANavigation>
                  <div className="center-btn">
                    <Link href="/create">
                      <a>
                        <button type="button">
                          Create a New Poll, for free <Icon size={20} icon={ic_arrow_forward} />
                        </button>
                      </a>
                    </Link>
                  </div>
                  <span className="question-helper">
                    Yup, It's free! No account or credit card required.
                  </span>
                </FooterCTANavigation>
              ) : (
                <FooterNavigator>
                  <div className="progress-navigator">
                    {showStageNavigation &&
                      pages.map((pag, i) => (
                        <button
                          type="button"
                          data-active={stage === i}
                          key={pag.id}
                          aria-label={`Stage ${i + 1}`}
                          disabled={loading || !stageNavigation}
                          aria-disabled={loading || !stageNavigation}
                          onClick={() => goToStage(i)}
                        />
                      ))}
                  </div>
                  <div className="back-btn">
                    {!disabledBack && (
                      <button
                        type="button"
                        disabled={loading || disabledBack}
                        aria-disabled={loading || disabledBack}
                        onClick={() => goToStage(stage - 1)}
                      >
                        {textLibrary[lang].go_back}
                      </button>
                    )}
                  </div>
                  <div className="next-btn">
                    <button
                      ref={submitBtnRef}
                      type="submit"
                      disabled={loading}
                      aria-disabled={loading}
                    >
                      {stage + 1 === pages.length
                        ? textLibrary[lang].finish
                        : textLibrary[lang].next}{' '}
                      <Icon size={24} icon={ic_chevron_right} />
                    </button>
                  </div>
                </FooterNavigator>
              )}

              <div className="form-footer">
                <PoweredBy embedded={embedded} />
              </div>
            </form>
          </div>
        )}
        {!page && (
          <FinishedMessage
            embedded={embedded}
            lang={lang}
            // stage={stage}
            // showStageNavigation={showStageNavigation}
            // disabledBack={disabledBack}
            // stageNavigation={stageNavigation}
            // pages={pages}
            // goToStage={goToStage}
          />
        )}
        {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
      </CardWrapper>
    </div>
  );
};

const FinishedMessage = ({ embedded, lang }) => (
  // const {
  //   // page,
  //   // stage,
  //   // showStageNavigation,
  //   // disabledBack,
  //   // stageNavigation,
  //   // pages,
  //   // goToStage
  // } = props;
  // const loading = false;
  <div className="card-body">
    <div />
    <div className="form-body">
      <FinishedMessageWrapper>
        <div>
          <div>{textLibrary[lang].thanks}!</div>
          <div>{textLibrary[lang].success_message}.</div>
        </div>
        <div className="img-wrapper">
          <Image
            src="/static/images/drawings/email_send.png"
            alt="pollme response success"
            layout="fill"
          />
        </div>
      </FinishedMessageWrapper>
    </div>
    {/**
            <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={loading}>
          <div className="form-body">
            <QuestionRenderer
              questions={page.elements}
              control={control}
              errors={errors}
              responses={responses}
              stageValues={stageValues}
              disabled={loading}
            />
          </div>
        </fieldset>
      </form>
       */}

    {/**
      <FooterNavigator>
        <div className="progress-navigator">
          {showStageNavigation &&
            pages.map((pag, i) => (
              <button
                type="button"
                data-active={stage === i}
                key={pag.id}
                aria-label={`Stage ${i + 1}`}
                disabled={loading || stageNavigation}
                aria-disabled={loading || stageNavigation}
                onClick={() => goToStage(i)}
              />
            ))}
        </div>
        <div className="back-btn">
          {!disabledBack && (
            <button
              type="button"
              disabled={loading || disabledBack}
              aria-disabled={loading || disabledBack}
              onClick={() => goToStage(stage - 1)}
            >
              Atrás
            </button>
          )}
        </div>


        <div className="next-btn">
          <button type="submit" disabled={loading} aria-disabled={loading}>
            {stage + 1 === pages.length ? 'Enviar' : 'Siguiente'}{' '}
            <Icon size={24} icon={ic_chevron_right} />
          </button>
        </div>
      </FooterNavigator>
           */}
    <div className="form-footer">
      <PoweredBy embedded={embedded} />
    </div>
  </div>
);

const PollWrapper = props => {
  const { model, onEditor, defaultValues = {} } = props;
  const [state, setState] = useState({
    stage: 0,
    modelID: model?.id || null,
    responses: {}
  });

  useEffect(() => {
    // if not on editor mode
    const getInitialResponseData = async () => {
      if (!onEditor) {
        // check local prev response data to use as poll state for current model
        let prevResponse = {};
        try {
          // try catch block for cross-origin errors
          prevResponse = await getLocalPollResponse(model.id);
        } catch (err) {
          prevResponse = {};
        }
        // console.log({ prevResponse });
        // check if POLL allows multiple response (to omit prev response and to submit new response)
        const { multiResponse = false } = model;
        // if multiResponse do not override initial data with prev response, otherwise, use prev response data
        const initialOverrideData = multiResponse ? {} : prevResponse;
        const initialResponses = {
          stage: 0,
          modelID: model.id,
          responses: {},
          ...initialOverrideData // override defaults with prev response
        };
        // console.log({ initialResponses });
        setState(initialResponses);
      }
    };
    getInitialResponseData();
  }, [model, onEditor]);

  // console.log({ state });
  const defaultResponseValues = { ...defaultValues, ...state.responses };
  // console.log({ initialResponse: state, defaultResponseValues });

  if (!model) {
    return null;
  }

  return (
    <PollProvider initialResponse={state}>
      <Poll {...props} defaultValues={defaultResponseValues} />
    </PollProvider>
  );
};
export default PollWrapper;
