import { createContext, useState, useEffect, useContext } from 'react';
import { saveToLocalResponse, saveLocalPollStage } from '@/utils/poll';

const INITIAL_VALUES = {
  stage: 0,
  modelID: null,
  responses: {}
};

const PollContext = createContext(INITIAL_VALUES);

export const PollProvider = props => {
  const { children, initialResponse } = props;
  const [state, setState] = useState(initialResponse || INITIAL_VALUES);
  // console.log({ state });
  useEffect(() => {
    // console.log('updating initial response');
    // console.log({ initialResponse });
    setState(initialResponse || INITIAL_VALUES);
  }, [initialResponse]);

  const saveLocalPoolResponse = async newState => {
    // console.log('saving local responses');
    const stateToSave = newState || state; // check if passing new state, otherwise, save current context state
    // console.log({ stateToSave });
    await saveToLocalResponse({
      modelID: state.modelID,
      responseBody: stateToSave
    });
    return Promise.resolve();
  };

  const setStage = async stage => {
    const pollKey = `poll@${state.modelID}`;
    try {
      await saveLocalPollStage({ pollKey, stage });
    } catch (err) {
      console.log(err);
    }
    await setState(prevState => ({
      ...prevState,
      stage
    }));
    // await saveLocalPoolResponse
    // await this.setState(state => ({
    //   ...state,
    //   stage: state.stage + 1
    // }));
    return Promise.resolve();
  };

  const setResponses = async newResponses => {
    const newState = {
      ...state,
      responses: newResponses
    };
    await setState(newState);
    await saveLocalPoolResponse(newState);
    return Promise.resolve();
  };

  const updateContext = async (newStateValues, onEditor) => {
    // console.log('on update context');
    const newState = {
      ...state,
      ...newStateValues
    };
    // console.log('saving context state');
    await setState(newState);
    if (!onEditor) {
      // if not editor, save to local
      await saveLocalPoolResponse(newState);
    }
    return Promise.resolve();
  };

  return (
    <PollContext.Provider
      value={{
        ...state,
        setResponses,
        setStage,
        updateContext,
        saveLocalPoolResponse
      }}
    >
      {children}
    </PollContext.Provider>
  );
};

export const PollConsumer = PollContext.Consumer;
export const usePollContext = () => useContext(PollContext);
